<template>
	<div class="CreatedMeterial" v-loading='loading' style="padding-bottom: 50px;">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
			<div class="baseInformtion">
				<div class="infortion">素材内容</div>
				<el-form-item label="素材类型" prop='MaterialType'>
					<el-select v-model="ruleForm.MaterialType" placeholder="请选择商品分组" @change='ChangeMeterial'>
						<el-option v-for="item in MeterialTypeList" :key="item.Id" :label="item.Name" :value="item.Id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="素材名称" prop='MaterialName'>
					<el-input maxlength="20" v-model="ruleForm.MaterialName" size="small" style="width:350px" clearable placeholder="最多输入20字"></el-input>
				</el-form-item>
				<div class="filter-container" v-if='ruleForm.MaterialType==1'>
					<el-form-item label="图片" prop="MaterialImgUrls">
						<div class="filter-item" style="vertical-align: top;">
							<upload-img @transPicture="transPictureFun" :AttachedImgUrls="ruleForm.MaterialImgUrls" :Type="2"></upload-img>
							<div class="textColor" style="margin-top:20px">拖拽图片可以调整顺序，每张图片大小请控制在2M以内；图片最多上传9张</div>
						</div>
					</el-form-item>
				</div>
				<el-form-item label="视频" prop="MaterialVideoUrl" v-if='ruleForm.MaterialType==2'>
					<div class="videoBox">
						<el-button type="text" style="font-size: 12px;" @click="deleteVideo" :disabled="!ruleForm.MaterialVideoUrl">删除视频</el-button>
						<el-upload :action="videoApi" :show-file-list="false" :on-success="handleVideoSuccess" list-type="picture-card"
						 :before-upload="beforeUploadVideo" :on-progress="uploadVideoProcess">
							<video v-if="ruleForm.MaterialVideoUrl" :src="imgUrl+ruleForm.MaterialVideoUrl" class="avatar" controls="controls" style="width:148px;height:148px">您的浏览器不支持视频播放</video>
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</div>
					<p class="textColor">建议使用16：9比例视频；视频大小请控制在6M以内</p>
				</el-form-item>

				<el-form-item label="文本描述">
					<div style="width:600px;position: relative;">
						<el-input type="textarea" v-model="ruleForm.Description" style="width:600px" maxlength="500"
						 placeholder='最多输入500字' @input="descInput" :rows="13" resize='none'>
						</el-input>
						<span class="numberV" style="position: absolute;right: 10px;bottom: 0;">{{txtVal}}/500</span>
					</div>

				</el-form-item>
			</div>
		</el-form>
		<!-- 底部保存 -->
		<div class="bottom-save-btn">
			<el-button style="width:240px" @click="canselGoods">取消</el-button>
			<el-button type='primary' style="width:240px;margin:0 10px 0 20px" @click="submitForm('ruleForm')" :loading="loading">保存</el-button>
		</div>
	</div>
</template>

<script>
	import {
		productMateriaSave,
		productMateriaInfo
	} from '@/api/TurnTomySelf'
	import config from '@/config/index'
	import uploadImg from '@/components/uploadImgs/uploadPicture';
	export default {
		components: {
			uploadImg,
		},
		data() {
			return {
				ProductId:0,
				txtVal: 0,
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
				videoApi: config.UPLOAD_VIDEO,
				loading: false,
				MeterialId: 0,
				ruleForm: {
					MaterialType: 1,
					MaterialName: '',
					MaterialImgUrls: [],
					MaterialVideoUrl: '',
					Description: ''
				},
				MeterialTypeList: [{
					Id: 1,
					Name: '图文'
				}, {
					Id: 2,
					Name: '视频'
				}],
				rules: {
					MaterialType: [{
						required: true,
						message: '请选择素材类型',
						trigger: 'change'
					}],
					MaterialName: [{
							required: true,
							message: '请输入素材名称',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 20,
							message: '长度在 1 到 20 个字符',
							trigger: 'blur'
						}
					],
					MaterialImgUrls: [{
						type: 'array',
						required: true,
						message: '素材图片至少上传1张',
						trigger: 'change'
					}],
					MaterialVideoUrl: [{
						required: true,
						message: '请上传素材视频',
						trigger: 'change'
					}]

				}
			}
		},
		created() {
			this.MeterialId = Number(this.$route.query.MeteriaId) ? this.$route.query.MeteriaId : 0
			this.ProductId =  Number(this.$route.query.ProductId) ? this.$route.query.ProductId : 0
			if(this.MeterialId){
				
				this.getMeterialInfo()
			}
		},
		methods: {
			//切换素材类型
			ChangeMeterial(){
				this.ruleForm.MaterialName = ''
				this.ruleForm.Description = ''
				if(this.ruleForm.MaterialType == 1){
					this.ruleForm.MaterialVideoUrl = ''
				}
				else{
					this.ruleForm.MaterialImgUrls = []
				}
			},
			submitForm(formName){
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.loading = true;
						try {
							if(this.ruleForm.MaterialImgUrls.length > 9){
								this.ruleForm.MaterialImgUrls = this.ruleForm.MaterialImgUrls.slice(0,9)
							}
							let data = {
								ProductId:this.ProductId,
								Id : this.MeterialId,
								MaterialName:this.ruleForm.MaterialName,
								MaterialType:this.ruleForm.MaterialType,
								MaterialImgUrls:this.ruleForm.MaterialImgUrls,
								MaterialVideoUrl: this.ruleForm.MaterialVideoUrl,
								Description:this.ruleForm.Description
							}
							let result = await productMateriaSave(data)
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								this.$router.push({
									path: '/goods/MeterialManagelist',
									query: {
										ProductId:this.ProductId?this.ProductId:0
									}
								});
							}
						} catch (error) {
							console.log(error)
						} finally {
							this.loading = false;
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			// 取消操作
			canselGoods() {
				this.$router.push({
					path: '/goods/MeterialManagelist',
					query: {
						ProductId:this.ProductId?this.ProductId:0
					}
				});
			},
			descInput() {
				this.txtVal = this.ruleForm.Description.length;
			},
			uploadVideoProcess(event, file, fileList) {
				this.videoFlag = true;
				this.videoUploadPercent = file.percentage.toFixed(0);
			},
			// 上传视频
			beforeUploadVideo(file) {
				const isLt6M = file.size / 1024 / 1024 < 6;

				if (['video/mp4'].indexOf(file.type) == -1) {
					this.$message.error('请上传正确的视频格式');
					return false;
				}

				if (!isLt6M) {
					this.$message.error('主图视频请控制在6M以内');
					return false;
				}
			},
			handleVideoSuccess(res, file) { //获取上传图片地址
				this.ruleForm.MaterialVideoUrl = res[0];
			},
			deleteVideo() {
				this.ruleForm.MaterialVideoUrl = ''
			},
			// 批量上传的图片
			transPictureFun(datas) {
				this.ruleForm.MaterialImgUrls = datas;
				// if(this.ruleForm.MaterialImgUrls.length > 9){
				// 	this.ruleForm.MaterialImgUrls = this.ruleForm.MaterialImgUrls.slice(0,9)
				// }
			},
			async getMeterialInfo() {
				try {
					this.loading = true
					let data = {
						Id:this.MeterialId
					}
					let result = await productMateriaInfo(data)
					if(result.IsSuccess){
						this.ruleForm = result.Result
					}
				} catch (err) {

				} finally {
					this.loading = false
				}
			}
		}
	}
</script>

<style lang="less">
	.CreatedMeterial {
		background: #fff;
		font-size: 15px;

		.baseInformtion {
			width: 100%;
			background: #fff;
			padding: 10px;
			margin-bottom: 12px;

			.infortion {
				margin-bottom: 20px;
				padding: 10px 0;
				border-bottom: 1px solid #ccc;
			}
		}

		.textColor {
			color: #999;
		}

		.bottom-save-btn {
			position: fixed;
			width: 100%;
			background: #fff;
			bottom: 0;
			text-align: center;
			padding: 20px 0;
			z-index: 100;
			box-shadow: 13px 1px 6px #999;
		}
	}
</style>
